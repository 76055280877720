<template>
  <div>

    <el-row type="flex" class="row-bg" justify="center" style="margin-top: 15px;">
      <el-avatar :size="128" src="https://pic.imgdb.cn/item/63a90c7c08b68301634742e8.jpg"></el-avatar>
    </el-row>

    <el-row type="flex" class="row-pb-sm" justify="center" style="margin-top: 15px;">
      <span>乌鸦</span>
    </el-row>

    <el-row type="flex" class="row-pb-sm" justify="center" style="margin-top: 15px;">
      <span>QQ:407374</span>
    </el-row>

    <el-row type="flex" class="row-bg" justify="center" style="margin-top: 15px;">
      <el-button icon="el-icon-user" @click="location.href='./menu/admin.html'">后台管理</el-button>
    </el-row>

    <el-row type="flex" class="row-bg" justify="center" style="margin-top: 15px;">
      <el-button icon="el-icon-circle-check" @click="location.href='./menu/crypto.html'">加密解密</el-button>
    </el-row>

    <el-row type="flex" class="row-bg" justify="center" style="margin-top: 15px;">
      <el-button icon="el-icon-search" @click="location.href='./menu/info.html'">信息搜索</el-button>
    </el-row>

    <el-row type="flex" class="row-bg" justify="center" style="margin-top: 15px;">
      <el-button icon="el-icon-zoom-in" @click="location.href='./menu/chatgpt.html'">智能搜索</el-button>
    </el-row>

    <el-row type="flex" class="row-bg" justify="center" style="margin-top: 15px;">
      <el-button icon="el-icon-film" @click="location.href='./menu/movies.html'">观影历史</el-button>
    </el-row>

  </div>
</template>

<script>


export default {
  name: 'IndexView',
}
</script>
